import { Posthog as capacitorPosthog } from "@capawesome/capacitor-posthog";
import { useQuery } from "@tanstack/react-query";
import posthog from "posthog-js";
import { useEffect } from "react";

import { isNative } from "utils/capacitor.utils";

import { accountUserQuery } from "./queries";

let isIdentified = false;
export const useUser = (options?: Partial<typeof accountUserQuery>) => {
  const { data, isLoading, refetch } = useQuery({
    ...accountUserQuery,
    ...options,
    retry: 1,
    staleTime: 0,
  });

  useEffect(() => {
    if (!data || !data.data || isIdentified) return;
    isIdentified = true;

    if (isNative) {
      capacitorPosthog.identify({
        distinctId: data.data.data.id,
        userProperties: {
          email: data.data.data.attributes.email,
          name: `${data.data.data.attributes.firstName} ${data.data.data.attributes.lastName}`,
        },
      });
    } else {
      posthog.identify(data.data.data.id, {
        email: data.data.data.attributes.email,
        name: `${data.data.data.attributes.firstName} ${data.data.data.attributes.lastName}`,
      });
    }
  }, [data]);

  return {
    data: data?.data,
    isLoading,
    refetch,
  };
};
