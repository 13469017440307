import { Posthog as capacitorPosthog } from "@capawesome/capacitor-posthog";
import posthog from "posthog-js";
import { useContext, useLayoutEffect, useRef, useState } from "react";

import { isNative } from "utils/capacitor.utils";

import { useResizeObserver } from "hooks";
import { CalendarMarkingProps, DaySection } from "types/calendar.types";

import CalendarMarkingText from "components/@calendar/CalendarMarking/CalendarMarkingText";
import {
  getMarkingColors,
  MarkerHoverContext,
} from "components/@calendar/utils";
import Modal from "components/@common/Modal";
import ReservationDetail from "components/@reservations/ReservationDetail/ReservationDetail/ReservationDetail";

import CalendarMarkingEnd from "./CalendarMarkingEnd";

const MARKER_HEIGHT = "55%";

const CalendarMarking = ({
  daySection,
  type,
  description,
  title,
  id,
  blockSpan,
  displayTitle,
  isInPast,
  listingId,
  onRemoveReservation,
  weekDayIndex,
}: CalendarMarkingProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { hoveredId: hoveredId, setHoveredId } = useContext(MarkerHoverContext);
  const ref = useRef<HTMLDivElement>(null);
  const [width] = useResizeObserver(ref);

  const colors = getMarkingColors(type, isInPast);

  const onClick = () => {
    setIsDialogOpen(true);

    // On request of Jens Roels we use the same url as the reservation detail page
    const url = `${window.location.origin}/reservations/listings/${listingId}/reservations/${id}`;
    if (isNative) {
      capacitorPosthog.capture({
        event: "$pageview",
        properties: {
          $current_url: url,
        },
      });
    } else {
      posthog.capture("$pageview", {
        $current_url: url,
      });
    }
  };

  const [markingTextWidth, setMarkingTextWidth] = useState(50);

  useLayoutEffect(() => {
    // hacky way to get the width of the text
    if (!displayTitle) return;

    const markingBlocks = document.querySelectorAll(`[data-marker-id="${id}"]`);
    const firstBlockOfMarking = markingBlocks.item(
      daySection === DaySection.START ? 0 : weekDayIndex,
    );
    const lastBlockOfMarking = markingBlocks.item(
      daySection === DaySection.START ? blockSpan : weekDayIndex + blockSpan,
    );

    const markingWidth = Math.abs(
      Math.abs(
        lastBlockOfMarking?.getBoundingClientRect().left -
          firstBlockOfMarking?.getBoundingClientRect().left,
      ) -
        (daySection === DaySection.MIDDLE && displayTitle
          ? firstBlockOfMarking?.getBoundingClientRect().left
          : firstBlockOfMarking?.getBoundingClientRect().width / 2),
    );

    setMarkingTextWidth(markingWidth);
  }, [displayTitle, blockSpan, id, daySection, width, weekDayIndex]);

  const isHovered = hoveredId === id;
  const boxShadow = isHovered ? `0px 4px 0px 0px ${colors.shadow}` : undefined;

  const commonMarkerStyleProps: React.CSSProperties = {
    position: "absolute",
    height: MARKER_HEIGHT,
    backgroundColor: colors.background,
    transition: "all 0.2s ease-in",
    boxShadow,
  };

  return (
    <>
      <div
        data-marker-id={id}
        className="calendar-marking"
        onMouseEnter={() => setHoveredId(id)}
        onMouseLeave={() => setHoveredId(null)}
        style={{ cursor: "pointer" }}
        ref={ref}
        onClick={onClick}
      >
        {daySection === DaySection.START && (
          <>
            <CalendarMarkingEnd
              height={MARKER_HEIGHT}
              isHovered={isHovered}
              isInPast={isInPast}
              position="start"
              type={type}
            />
            <div
              style={{
                right: 0,
                width: "45%",
                zIndex: 1,
                ...commonMarkerStyleProps,
              }}
            >
              {displayTitle && (
                <CalendarMarkingText
                  title={title}
                  description={description}
                  width={markingTextWidth}
                  colors={colors}
                />
              )}
            </div>
          </>
        )}
        {daySection === DaySection.MIDDLE && (
          <>
            <div
              style={{
                left: 0,
                right: 0,
                paddingLeft: 12,
                zIndex: displayTitle ? 1 : 0,
                ...commonMarkerStyleProps,
              }}
            >
              {displayTitle && (
                <CalendarMarkingText
                  title={title}
                  description={description}
                  width={markingTextWidth}
                  colors={colors}
                />
              )}
            </div>
          </>
        )}
        {daySection === DaySection.END && (
          <>
            <CalendarMarkingEnd
              height={MARKER_HEIGHT}
              isHovered={isHovered}
              isInPast={isInPast}
              position="end"
              type={type}
            />
            <div
              style={{
                left: 0,
                width: "25%",
                ...commonMarkerStyleProps,
              }}
            />
          </>
        )}
      </div>
      <Modal isOpen={isDialogOpen} onDidDismiss={() => setIsDialogOpen(false)}>
        <ReservationDetail
          isModal
          listingId={listingId}
          reservationId={id}
          hideBack
          useDesktopButtons
          onClose={() => setIsDialogOpen(false)}
          onRemove={onRemoveReservation}
          type={type}
        />
      </Modal>
    </>
  );
};

export default CalendarMarking;
