import config from "config";

import { isNative } from "./capacitor.utils";
import { isDev } from "./env.utils";
import { getTopLevelDomainFromCurrentLocation } from "./location.utils";

/**
 * Get the Authentication callback URL for amplify/cognito
 *
 * @returns
 */
export const getAuthCallbackUrl = () => {
  if (isNative) {
    return `${config.AUTH_REDIRECT_SCHEME}/${config.AUTH_REDIRECT_URL}`;
  }

  return `${window.location.origin}/${config.AUTH_REDIRECT_URL}`;
};

export const getWebFrontendUrl = () => {
  const tld = getTopLevelDomainFromCurrentLocation();
  let frontendUrl = isDev()
    ? (process.env.REACT_APP_API_REDIRECT_AMPLIFY_URL ?? "")
    : `${process.env.REACT_APP_API_REDIRECT_AMPLIFY_URL}.${tld}`;

  if (frontendUrl.endsWith("/")) {
    frontendUrl = frontendUrl.slice(0, -1);
  }

  return frontendUrl;
};
