import "aws-amplify/auth/enable-oauth-listener";

import { Amplify } from "aws-amplify";

import { getAuthCallbackUrl, getWebFrontendUrl } from "utils/url.utils";

const webFrontendUrl = getWebFrontendUrl();

export const configureAWS = () => {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID ?? "",
        userPoolClientId: process.env.REACT_APP_AWS_USER_POOL_CLIENT_ID ?? "",
        loginWith: {
          oauth: {
            domain: process.env.REACT_APP_OAUTH_DOMAIN ?? "",
            scopes: [
              "profile",
              "email",
              "openid",
              "aws.cognito.signin.user.admin",
            ],
            redirectSignIn: [getAuthCallbackUrl()],
            redirectSignOut: [`${webFrontendUrl}/sign-in/continue`],
            responseType: "code",
          },
        },
      },
    },
  });
};
