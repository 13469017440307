import { JsonResponse } from "./general.types";

export enum RequirementsAnswer {
  Yes = "yes",
  No = "no",
}

export type ReviewListingProviderValues = {
  listingId: string;
  // availability
  availabilities: Availabilities;
};

export type CompleteListingProviderValues = {
  listingId: string;
  // About
  title?: string;
  neighborhood?: string;
  unit?: string;
  region?: string;
  appartment?: string;
  floorAmount?: number;
  floorNumber?: number;
  // Amenities
  amenities: Array<string>;
  // Sleeping Arrangements
  sleepingArrangements: Array<SleepingArrangement>;
  bedrooms: number;
  // Pictures
  pictures: Array<string>;
  // House Rules
  houseRules: Array<HouseRule>;
  // Regulations
  regulations: {
    license: string;
    documents: Array<string>;
  };
};

export type CreateListingProviderValues = {
  listingId?: string;
  // Address
  country: string;
  street: string;
  additionalInformation?: string;
  zipcode: string;
  city: string;
  // Propery Details
  propertyType?: PropertyType;
  propertyKind?: PropertyKind;
  bathrooms: number;
  bedrooms: number;
  toilets: number;
  maximumOccupancy: number;
  // URL that will be used to scrape property details
  originalUrl: string;
  // Requirements
  requirements?: RequirementsAnswer;
  // Pricing Plan
  pricingPlan: PricingPlan | null;
};

export type Address = {
  street: string;
  city: string;
  zipcode: string;
  country: string;
  additionalInformation?: string;
  unit?: string | null;
  region?: string | null;
  appartment?: string | null;
  floorNumber?: number | null;
  floorAmount?: number | null;
};

export type HouseRule = {
  name: string;
  value: string | boolean;
};

export enum RoomType {
  Bedroom = "bedroom",
  SharedSpace = "shared_space",
}

export enum BedType {
  KING_BED = "KING_BED",
  QUEEN_BED = "QUEEN_BED",
  DOUBLE_BED = "DOUBLE_BED",
  SINGLE_BED = "SINGLE_BED",
  SOFA_BED = "SOFA_BED",
  AIR_MATTRESS = "AIR_MATTRESS",
  BUNK_BED = "BUNK_BED",
  FLOOR_MATTRESS = "FLOOR_MATTRESS",
  WATER_BED = "WATER_BED",
  TODDLER_BED = "TODDLER_BED",
  CRIB = "CRIB",
}

export type SleepingArrangement = {
  roomType: RoomType;
  bedTypes: Array<BedType>;
};

export enum PropertyType {
  House = "HOUSE",
  Apartment = "APPARTMENT",
  // Hotel = "HOTEL",
  Other = "OTHER",
}
export enum PropertyKind {
  Place = "PLACE",
  Room = "ROOM",
}
export enum ListingStatus {
  New = "NEW",
  Onboarding = "ONBOARDING",
  Preparing = "PREPARING",
  ReadyToPublish = "READY_TO_PUBLISH",
  Active = "ACTIVE",
  Suspended = "SUSPENDED",
  Inactive = "INACTIVE",
}

type CustomSchema = {
  startDate: string;
  endDate: string;
};

export enum DefaultSchema {
  Always = "ALWAYS",
  Custom = "CUSTOM",
}

export type Availabilities = {
  defaultSchema: DefaultSchema;
  customSchema: Array<CustomSchema> | null;
  bookingWindow: string | null;
  advanceNotice: string | null;
};

type marketingProperties = {
  summary: string;
  neighbourhood: string;
  transit: string;
  notes: string;
  interactionWithGuests: string;
  space: string;
  access: string;
};
export enum PricingPlan {
  Starter = "starter",
  Professional = "professional",
  Premium = "premium",
  Manual = "manual",
}
export type UpdateListingAttributes = Partial<{
  guestyId: string;
  title: string;
  nickname: string;
  address: Partial<Address>;
  mainImage: string;
  averageRating: number;
  totalRatings: number;
  minimumNights: number;
  propertyType: PropertyType;
  propertyKind: PropertyKind;
  maximumOccupancy: number;
  bedrooms: number;
  beds: number;
  bathrooms: number;
  amenities: Array<string>;
  pictures: Array<string>;
  taxInfo: string;
  houseRules: Array<HouseRule>;
  insuranceInfo: string;
  status: ListingStatus;
  availabilities: Availabilities;
  pricingPlan: PricingPlan;
  pricingPlanCommission: number;
  showcasePictures: Array<string>; // deprecated
  toilets: number;
  neighborhood: string;
  sleepingArrangements: Array<SleepingArrangement>;
  regulations: {
    license: string;
    documents: Array<string>;
  };
  financial: FinancialValues;
  payout: PayoutValues;
}>;

export type CreateListingResponse = {
  data: {
    id: string;
    type: string;
    attributes: UpdateListingAttributes;
  };
};
export enum OverviewStep {
  Creation = "creation",
  Details = "details",
  Setup = "setup",
  Review = "review",
  Publish = "publish",
}
export type ListingResponse = {
  data: {
    id: string;
    type: "listings";
    attributes: {
      guestyId: string;
      title: string;
      nickname: string;
      address: Address;
      mainImage: string;
      averageRating: number;
      totalRatings: number;
      minimumNights: number;
      propertyType: PropertyType;
      propertyKind: PropertyKind;
      maximumOccupancy: number;
      bedrooms: number;
      beds: number;
      bathrooms: number;
      amenities: Array<string>;
      pictures: Array<string>;
      taxInfo: string;
      houseRules: Array<HouseRule>;
      insuranceInfo: string;
      status: ListingStatus;
      pricingPlan: PricingPlan;
      pricingPlanCommision: number;
      toilets: number;
      neighborhood: string;
      originalUrl: string;
      availabilities: Availabilities;
      marketingProperties: marketingProperties;
      sleepingArrangements: Array<SleepingArrangement>;
      regulations: { license: string; documents: Array<string> } | null;
      financial: FinancialValues;
      payout: PayoutValues;
    };
    relationships: {
      otas: OtasResponse;
      owners: {
        data: Array<Owner>;
      };
    };
  };
};

export type Owner = {
  id: string;
  type: "owners";
  attributes: {
    email: string;
    firstName: string;
    lastName: string;
    ownerType: OwnerType;
    phone: string;
  };
};

export enum OwnerType {
  SUB = "SUB",
  MAIN = "MAIN",
}

export type FinancialValues = {
  name: string;
  vatNumber: string;
  billingAddress: string;
  billingEmail: string;
};

export type PayoutValues = {
  bankAccountNumber: string;
  bankAccountOwnerName: string;
  bankName: string;
  bankCountry: string;
  bankCode: string;
};

type Ota = {
  id: string;
  type: string;
  attributes: {
    externalUrl: string;
    logoUrl: string;
    name: string;
    premium: boolean;
    default: boolean;
  };
};

export type Amenities = {
  essentials: Array<string>;
  favourites: Array<string>;
  accessibility: Array<string>;
  bathroom: Array<string>;
  bedroom: Array<string>;
  entertainment: Array<string>;
  family: Array<string>;
  heating: Array<string>;
  safety: Array<string>;
  kitchen: Array<string>;
  location: Array<string>;
  logistics: Array<string>;
  outdoor: Array<string>;
  parking: Array<string>;
  pool: Array<string>;
  services: Array<string>;
  wellness: Array<string>;
};

export type OtasResponse = {
  data: Array<Ota>;
};

export type ListingInsightsResponse = JsonResponse<ListingInsights>;

export type MonthlyInsight = {
  revenue: number;
  cumulativeRevenue: number;
  index: number;
};

export type ListingReviewsInsights = {
  id: string;
  type: "listings";
  attributes: {
    cleanliness: number | null;
    location: number | null;
    value: number | null;
    accuracy: number | null;
    checkIn: number | null;
    communication: number | null;
    facilities: number | null;
    comfort: number | null;
    staff: number | null;
    overallScore: number;
    numberOfReviews: number;
  };
  relationships: {
    lastReviewReservation: {
      data: {
        id: string;
        type: string;
        attributes: {
          reviewText: string;
        };
      };
    };
  };
};

export type ListingReviewsInsightsResponse =
  JsonResponse<ListingReviewsInsights>;

export type ListingInsights = {
  id: string;
  type: "listings";
  attributes: {
    monthlyInsights: Array<MonthlyInsight>;
    revenueYtd?: number;
    revenueUpcomingBookings?: number;
    revenueTotal: number;
  };
  meta: {
    isNextAvailable: boolean;
    isPreviousAvailable: boolean;
  };
  links: {
    previousYear?: string;
    nextYear?: string;
  };
};

export type SuccessManager = {
  id: string;
  email: string;
  type: string;
  firstName: string;
  lastName: string;
  userId: number;
  userIdIncludingInactive: number;
  createdAt: string;
  updatedAt: string;
  archived: boolean;
};

export type AmenityItem = {
  value: I18nKey;
  label?: I18nKey;
};
export type AmenityItems = Record<string, AmenityItem>;
