import { useIonRouter } from "@ionic/react";
import { useIsRestoring, useQueryClient } from "@tanstack/react-query";
import { AuthUser, getCurrentUser } from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import { Routes } from "constants/routes.constants";
import { ACCOUNT_PREFIX, accountUserQuery, useUser } from "queries";
import { useAuthStore } from "store/auth-store";

import { AuthContext } from "./context";
import { CustomState } from "./types";

interface Props {
  children: ReactNode;
}

export const AuthProvider = ({ children }: Props) => {
  const queryClient = useQueryClient();
  const [authUser, setAuthUser] = useState<AuthUser | undefined>();
  const [signInError, setSignInError] = useState<boolean>(false);
  const { setValues } = useAuthStore((state) => state.actions);
  const router = useIonRouter();

  // Returns true if we are restoring from localStorage -> queryClient cache
  const isRestoring = useIsRestoring();

  // Fetches the user but only if we are authenticated and stores it in localStorage
  const { data, isLoading } = useUser({
    enabled: !!authUser,
    meta: { localStorage: true },
  });

  const handleAuthenticated = useCallback((identity: AuthUser) => {
    setAuthUser(identity);
  }, []);

  const handleUnauthenticated = useCallback(() => {
    setAuthUser(undefined);
    queryClient.removeQueries({ queryKey: [ACCOUNT_PREFIX] });
  }, [queryClient]);

  const handleRedirectAfterSignIn = useCallback(
    async (callback: string) => {
      const customState: CustomState = JSON.parse(callback);
      const provider = customState.provider;

      const user = await queryClient
        .ensureQueryData({
          ...accountUserQuery,
          meta: { localStorage: true },
        })
        .catch((error) => {
          console.error("account user query failed:", { error });
          return undefined;
        });

      const profileCompleted = !!user?.data?.data.attributes?.profileCompleted;
      const targetRoute = profileCompleted
        ? Routes.Listings
        : Routes.SignUpDetails;

      setValues({ loginProvider: provider });
      router.push(targetRoute, "root", "replace");
    },
    [queryClient, router, setValues],
  );

  const handleRedirectAfterFailedSignIn = useCallback(() => {
    setTimeout(() => {
      router.push(Routes.SignInContinue, "back");
    }, 0);
  }, [router]);

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload }) => {
      switch (payload.event) {
        case "signedIn": {
          setSignInError(false);
          handleAuthenticated(payload.data);
          break;
        }
        case "signInWithRedirect": {
          setSignInError(false);
          break;
        }
        case "signedOut": {
          setSignInError(false);
          handleUnauthenticated();
          break;
        }
        case "customOAuthState":
          handleRedirectAfterSignIn(payload.data);
          break;
        case "signInWithRedirect_failure": {
          // This is not triggered when user cancels the social sign in, the hub listener is loaded too late
          // We manually redirect after 15 seconds in the callback page

          setSignInError(true);
          handleRedirectAfterFailedSignIn();
          handleUnauthenticated();

          break;
        }
        default:
          break;
      }
    });

    return unsubscribe;
  }, [
    handleAuthenticated,
    handleRedirectAfterFailedSignIn,
    handleRedirectAfterSignIn,
    handleUnauthenticated,
  ]);

  // On mount, check the status and act on it
  const checkAuthStatus = useCallback(async () => {
    try {
      const authUser = await getCurrentUser();
      handleAuthenticated(authUser);
    } catch (e) {
      handleUnauthenticated();
    }
  }, [handleAuthenticated, handleUnauthenticated]);

  useEffect(() => {
    checkAuthStatus();
  }, [checkAuthStatus]);

  const value = useMemo(
    () => ({
      user: data,
      isLoggedIn: !!authUser && !!data,
      isLoading: (!!authUser && isLoading) || isRestoring,
      signInError,
    }),
    [data, authUser, isLoading, isRestoring, signInError],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
