import { useQuery } from "@tanstack/react-query";

import { getListingReviewsInsightsQuery } from "./queries";

export const useListingReviewsInsights = (
  id: string,
  year?: number,
  options?: Partial<ReturnType<typeof getListingReviewsInsightsQuery>>,
) => {
  const activeYear = year || new Date().getFullYear();

  const { data, isLoading, error, isError } = useQuery({
    ...getListingReviewsInsightsQuery(id, activeYear),
    ...options,
  });

  return {
    data: data?.data.data,
    activeYear,
    error,
    isLoading,
    isError,
  };
};
