import { Posthog as capacitorPosthog } from "@capawesome/capacitor-posthog";
import posthogWeb from "posthog-js";

import { isNative } from "utils/capacitor.utils";

export function posthogInit({
  apiKey,
  apiHost,
}: {
  apiKey: string;
  apiHost: string;
}) {
  const config = {
    api_host: apiHost,
    autocapture: true,
    capture_pageview: false,
    capture_pageleave: true,
    disable_session_recording: true,
  };

  if (isNative) {
    capacitorPosthog.setup({
      host: config.api_host,
      apiKey,
    });
  } else {
    posthogWeb.init(apiKey, config);
  }
}
