import "./ListingOverview.scss";

import { IonSkeletonText } from "@ionic/react";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { useListingReviewsInsights } from "queries";

import ListingReview from "components/@common/ListingReview/ListingReview";

import { getListingId } from "../utils";
import Chart from "./Chart/Chart";
import Reservations from "./Reservations/Reservations";
import Support from "./Support/Support";

const ListingOverview = () => {
  const listingId = getListingId();

  // TODO: check if this year needs to come from the chart
  const { data, isLoading } = useListingReviewsInsights(
    listingId,
    new Date().getFullYear(),
    { enabled: !!listingId },
  );

  useEffect(() => {
    document.body.classList.add("overview-page");
    return () => document.body.classList.remove("overview-page");
  }, []);

  return (
    <div className="listing-overview">
      <Chart />
      {isLoading && (
        <div className="review-loading">
          <IonSkeletonText
            animated={true}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      )}
      {!!data && !isLoading && (
        <div className="rating-block">
          <h5 className="margin-0">
            <FormattedMessage id="reservation_detail.satisfaction_title" />
          </h5>

          <ListingReview listingReviewInsights={data} />
        </div>
      )}
      <Reservations />
      <Support />
    </div>
  );
};

export default ListingOverview;
