import { App, URLOpenListenerEvent } from "@capacitor/app";
import { useEffect } from "react";
import { useHistory } from "react-router";

import { isNative } from "utils/capacitor.utils";

const AppUrlListener = () => {
  const history = useHistory();
  useEffect(() => {
    // Add the listener for app open URL events
    App.addListener("appUrlOpen", ({ url }: URLOpenListenerEvent) => {
      const _url = new URL(url);
      const pathname = _url.pathname;
      const searchParams = _url.searchParams;

      if (pathname) {
        let path = pathname;
        if (searchParams.size > 0) {
          path += `?${searchParams.toString()}`;
        }

        if (isNative) {
          window.location.replace(`${window.location.origin}${path}`);
        } else {
          history.push(path);
        }
      }
    });

    return () => {
      // Clean up the listener when the component is unmounted
      App.removeAllListeners();
    };
  }, [history]);

  return null;
};

export default AppUrlListener;
