import { Posthog as capacitorPosthog } from "@capawesome/capacitor-posthog";
import posthog from "posthog-js"; // Ensure posthog is imported
import { useEffect, useRef } from "react";
import { matchPath, useLocation } from "react-router-dom";

import { isNative } from "utils/capacitor.utils";

import { Routes } from "constants/routes.constants";

const getRouteKey = (currentPath: string) => {
  // First try exact match
  const exactMatch = Object.entries(Routes).find(
    ([_, path]) => path === currentPath,
  );
  if (exactMatch) return exactMatch[0];

  // Try matching with parameters using matchPath
  const matchingRoute = Object.entries(Routes).find(([_, path]) =>
    matchPath(currentPath, {
      path,
      exact: true,
    }),
  );

  return matchingRoute?.[0];
};

export default function PageViewProvider() {
  const location = useLocation();
  const previousUrlRef = useRef<string>();

  useEffect(() => {
    // Get the current URL including hash and search params
    const currentUrl = window.location.href;
    // Arbitrary key to identify the route
    const routeKey = getRouteKey(location.pathname) || "";

    // Track in PostHog
    if (isNative) {
      capacitorPosthog.capture({
        event: "$pageview",
        properties: {
          $current_url: currentUrl,
        },
      });
    } else {
      posthog.capture("$pageview", {
        $current_url: currentUrl,
      });
    }

    // Push to dataLayer (GTM)
    if (window.dataLayer && document.getElementById("google-tag-manager")) {
      window.dataLayer.push({
        event: "virtualPageview",
        pageUrl: currentUrl,
        pageTitle: routeKey,
        previousUrl: previousUrlRef.current || document.referrer || "",
      });
    }

    // Store current URL for next navigation
    previousUrlRef.current = currentUrl;

    /**
     * Using individual location properties instead of the full location object
     * prevents unnecessary re-renders from other location object updates, and minimizes risks
     */
  }, [location.pathname, location.hash, location.search]);

  return null;
}
