import {
  Address,
  Amenities,
  CreateListingResponse,
  ListingInsightsResponse,
  ListingResponse,
  ListingReviewsInsightsResponse,
  OtasResponse,
  SuccessManager,
  UpdateListingAttributes,
} from "types/listing.types";

import RestApiService from "./";

export default {
  getListing(this: RestApiService, id: string) {
    return this.api.get<ListingResponse>(`/v1/listings/${id}`);
  },

  postListing(this: RestApiService, address: Address) {
    return this.api.post<CreateListingResponse>("/v1/listings", {
      data: {
        type: "listings",
        attributes: {
          address,
        },
      },
    });
  },

  updateListing(
    this: RestApiService,
    id: string,
    attributes: UpdateListingAttributes,
  ) {
    return this.api.patch(`/v1/listings/${id}`, {
      data: {
        id,
        type: "listings",
        attributes,
      },
    });
  },

  getListingInsights(this: RestApiService, id: string, year: number) {
    return this.api.get<ListingInsightsResponse>(
      `/v1/listings/${id}/insights`,
      {
        params: {
          year,
        },
      },
    );
  },

  getListingReviewsInsights(this: RestApiService, id: string, year: number) {
    return this.api.get<ListingReviewsInsightsResponse>(
      `/v1/listings/${id}/review_insights`,
      {
        params: {
          year,
        },
      },
    );
  },

  inviteOwner(this: RestApiService, listingId: string, email: string) {
    return this.api.post(`/v1/listings/${listingId}/owners/invite`, {
      data: { type: "listings", attributes: { email } },
    });
  },

  revokeOwnerAccess(this: RestApiService, ownerId: string, listingId: string) {
    return this.api.delete(`/v1/owners/${ownerId}/listings/${listingId}`, {
      headers: { "Content-Type": "application/vnd.api+json" },
      data: {},
    });
  },

  getAmenities(this: RestApiService) {
    return this.api.get<Amenities>("/v1/onboarding/amenities");
  },

  getOtas(this: RestApiService) {
    return this.api.get<OtasResponse>("/v1/otas");
  },

  getListingSuccessManager(this: RestApiService, id: string) {
    return this.api.get<SuccessManager>(`/v1/listings/${id}/success-manager`);
  },
};
