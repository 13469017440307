import "./ListingReview.scss";

import { IonIcon } from "@ionic/react";
import { FormattedMessage } from "react-intl";

import { formatRating } from "utils/reservation.utils";

import { ListingReviewsInsights } from "types/listing.types";

import Rating from "components/@common/Rating";

interface Props {
  listingReviewInsights: ListingReviewsInsights;
}

const ListingReview = ({ listingReviewInsights }: Props) => {
  if (!listingReviewInsights) return null;

  const review = listingReviewInsights.attributes;

  return (
    <div className="info-block">
      <div className="public-reviews">
        <div className="item large-array">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <p className="bold opacity margin-0">
              <FormattedMessage id="public_review.overall_rating" />
            </p>

            <div className="numeric">
              <h2>
                {review.overallScore === null
                  ? "-"
                  : formatRating(review.overallScore)}
              </h2>
              <h4 className="margin-0 bold divisor">/5</h4>
            </div>

            <Rating rating={review.overallScore} stars small />
          </div>

          <div className="divider-vertical"></div>

          <div className="total-reviews">
            <p className="bold opacity margin-0">
              <FormattedMessage id="public_review.total_reviews" />
            </p>
            <h4>{review.numberOfReviews}</h4>
          </div>
        </div>
        <div className="item">
          <div className="review-item">
            <div className="review-item-title">
              <p className="bold opacity margin-0">
                <FormattedMessage id="reservation_detail_review.location" />
              </p>

              <Rating rating={review.location} />
            </div>
            <IonIcon src="assets/icons/icon-location.svg" />
          </div>
        </div>
        <div className="item">
          <div className="review-item">
            <div className="review-item-title">
              <p className="bold opacity margin-0">
                <FormattedMessage id="reservation_detail_review.cleanliness" />
              </p>

              <Rating rating={review.cleanliness} />
            </div>
            <IonIcon src="assets/icons/icon-spray.svg" />
          </div>
        </div>
        <div className="item">
          <div className="review-item">
            <div className="review-item-title">
              <p className="bold opacity margin-0">
                <FormattedMessage id="reservation_detail_review.value" />
              </p>

              <Rating rating={review.value} />
            </div>
            <IonIcon src="assets/icons/icon-tag.svg" />
          </div>
        </div>
        {review.accuracy && review.accuracy > 0 ? (
          <>
            <div className="item">
              <div className="review-item">
                <div className="review-item-title">
                  <p className="bold opacity margin-0">
                    <FormattedMessage id="reservation_detail_review.accuracy" />
                  </p>
                  <Rating rating={review.accuracy} />
                </div>
                <IonIcon src="assets/icons/icon-accuracy.svg" />
              </div>
            </div>

            <div className="item">
              <div className="review-item">
                <div className="review-item-title">
                  <p className="bold opacity margin-0">
                    <FormattedMessage id="reservation_detail_review.checkin" />
                  </p>
                  <Rating rating={review.checkIn} />
                </div>
                <IonIcon src="assets/icons/icon-checkin.svg" />
              </div>
            </div>

            <div className="item">
              <div className="review-item">
                <div className="review-item-title">
                  <p className="bold opacity margin-0">
                    <FormattedMessage id="reservation_detail_review.communication" />
                  </p>
                  <Rating rating={review.communication} />
                </div>
                <IonIcon src="assets/icons/icon-communication.svg" />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="item">
              <div className="review-item">
                <div className="review-item-title">
                  <p className="bold opacity margin-0">
                    <FormattedMessage id="reservation_detail_review.facilities" />
                  </p>
                  <Rating rating={review.facilities} />
                </div>
                <IonIcon src="assets/icons/icon-appartment.svg" />
              </div>
            </div>
            <div className="item">
              <div className="review-item">
                <div className="review-item-title">
                  <p className="bold opacity margin-0">
                    <FormattedMessage id="reservation_detail_review.comfort" />
                  </p>
                  <Rating rating={review.comfort} />
                </div>
                <IonIcon src="assets/icons/icon-bed.svg" />
              </div>
            </div>
            <div className="item">
              <div className="review-item">
                <div className="review-item-title">
                  <p className="bold opacity margin-0">
                    <FormattedMessage id="reservation_detail_review.staff" />
                  </p>
                  <Rating rating={review.staff} />
                </div>
                <IonIcon src="assets/icons/icon-guests.svg" />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ListingReview;
