import { AxiosInstance } from "axios";

import blocksApi from "./blocks.api";
import calendarApi from "./calendar.api";
import documentApi from "./document.api";
import listingApi from "./listing.api";
import reservationApi from "./reservation.api";
import scraperApi from "./scraper.api";
import uploadApi from "./upload.api";
import userApi from "./user.api";

interface Options {
  api: AxiosInstance;
}

class RestApiService {
  public api: AxiosInstance;

  constructor(options: Options) {
    this.api = options.api;
  }

  // USER
  public getUser = userApi.getUser;
  public updateUser = userApi.updateUser;

  // DOCUMENT
  public getTermsAndConditionsPage = documentApi.getTermsAndConditionsPage;
  public getPrivacyPolicyPage = documentApi.getPrivacyPolicyPage;
  public getRequirementsPage = documentApi.getRequirementsPage;
  public getGraphInformationPage = documentApi.getGraphInformationPage;

  // LISTING
  public getListing = listingApi.getListing;
  public getListingInsights = listingApi.getListingInsights;
  public getListingReviewsInsights = listingApi.getListingReviewsInsights;
  public postListing = listingApi.postListing;
  public updateListing = listingApi.updateListing;
  public inviteOwner = listingApi.inviteOwner;
  public revokeOwnerAccess = listingApi.revokeOwnerAccess;
  public getAmenities = listingApi.getAmenities;
  public getOtas = listingApi.getOtas;
  public getListingSuccessManager = listingApi.getListingSuccessManager;

  // SCRAPER
  public postListingScraper = scraperApi.postListingScraper;

  // UPLOAD
  public requestPresignedUrl = uploadApi.requestPresignedUrl;

  // CALENDAR
  public getCalendarListing = calendarApi.getCalendarListing;

  // RESERVATION
  public getReservations = reservationApi.getReservations;
  public getReservation = reservationApi.getReservation;
  public getBlock = reservationApi.getBlock;
  public updateReservation = reservationApi.updateReservation;
  public updateBlock = reservationApi.updateBlock;
  public deleteReservation = reservationApi.deleteReservation;
  public requestCancelReservation = reservationApi.requestCancelReservation;
  public createReservation = reservationApi.createReservation;

  // BLOCKS
  public getBlockReasons = blocksApi.getBlockReasons;
  public createBlock = blocksApi.createBlock;
}

export default RestApiService;
