import "./PublicReview.scss";

import { IonIcon } from "@ionic/react";
import { FormattedMessage } from "react-intl";

import { formatRating } from "utils/reservation.utils";

import {
  AirBnbReview,
  BookingComReview,
  PublicReview as PublicReviewType,
} from "types/reservation.types";

import Rating from "components/@common/Rating";

interface Props {
  publicReview: PublicReviewType;
}

const safeDivide = (total: number, count: number) =>
  count > 0 ? total / count : 0;

/**
 * TODO: when we change the UI of the public review, merge it with the ListingReview component and remove this one
 * @see ListingReview
 */
const PublicReview = ({ publicReview }: Props) => {
  const rawReview = publicReview.rawReview;

  const isBookingCom = (
    review: AirBnbReview | BookingComReview,
  ): review is BookingComReview => {
    return (review as BookingComReview).positiveFeedback !== undefined;
  };

  const isAirBnb = (
    review: AirBnbReview | BookingComReview,
  ): review is AirBnbReview => {
    return (review as AirBnbReview).publicReview !== undefined;
  };

  const clampedOverallRating = Math.min(
    safeDivide(rawReview.overallRating, 2),
    5,
  );
  const locationScore = safeDivide(rawReview.locationScore, 2);
  const cleanlinessScore = safeDivide(rawReview.cleanlinessScore, 2);
  const valueScore = safeDivide(rawReview.valueScore, 2);

  const accuracyScore = isAirBnb(rawReview)
    ? rawReview.accuracyScore
    : undefined;
  const checkinScore = isAirBnb(rawReview) ? rawReview.checkinScore : undefined;
  const communicationScore = isAirBnb(rawReview)
    ? rawReview.communicationScore
    : undefined;

  const facilitiesScore = isBookingCom(rawReview)
    ? safeDivide(rawReview.facilitiesScore, 2)
    : undefined;
  const comfortScore = isBookingCom(rawReview)
    ? safeDivide(rawReview.comfortScore, 2)
    : undefined;
  const staffScore = isBookingCom(rawReview)
    ? safeDivide(rawReview.staffScore, 2)
    : undefined;

  return (
    <div className="info-block">
      <div className="public-review">
        <div className="item large">
          <div className="rating-title">
            <h4>{formatRating(clampedOverallRating)}</h4>
            <Rating rating={clampedOverallRating} stars />
          </div>
          {isBookingCom(rawReview) && rawReview.positiveFeedback && (
            <p className="margin-0 extra-small">{rawReview.positiveFeedback}</p>
          )}
          {isAirBnb(rawReview) && rawReview.publicReview && (
            <p className="margin-0 extra-small">{rawReview.publicReview}</p>
          )}
        </div>
        <div className="item">
          <div className="review-item">
            <div className="review-item-title">
              <p className="extra-small bold opacity margin-0">
                <FormattedMessage id="reservation_detail_review.location" />
              </p>

              <Rating rating={locationScore} />
            </div>
            <IonIcon src="assets/icons/icon-location.svg" />
          </div>
        </div>
        <div className="item">
          <div className="review-item">
            <div className="review-item-title">
              <p className="extra-small bold opacity margin-0">
                <FormattedMessage id="reservation_detail_review.cleanliness" />
              </p>

              <Rating rating={cleanlinessScore} />
            </div>
            <IonIcon src="assets/icons/icon-spray.svg" />
          </div>
        </div>
        <div className="item">
          <div className="review-item">
            <div className="review-item-title">
              <p className="extra-small bold opacity margin-0">
                <FormattedMessage id="reservation_detail_review.value" />
              </p>

              <Rating rating={valueScore} />
            </div>
            <IonIcon src="assets/icons/icon-tag.svg" />
          </div>
        </div>
        {isAirBnb(rawReview) && (
          <>
            <div className="item">
              <div className="review-item">
                <div className="review-item-title">
                  <p className="extra-small bold opacity margin-0">
                    <FormattedMessage id="reservation_detail_review.accuracy" />
                  </p>
                  <Rating rating={accuracyScore} />
                </div>
                <IonIcon src="assets/icons/icon-accuracy.svg" />
              </div>
            </div>

            <div className="item">
              <div className="review-item">
                <div className="review-item-title">
                  <p className="extra-small bold opacity margin-0">
                    <FormattedMessage id="reservation_detail_review.checkin" />
                  </p>
                  <Rating rating={checkinScore} />
                </div>
                <IonIcon src="assets/icons/icon-checkin.svg" />
              </div>
            </div>

            <div className="item">
              <div className="review-item">
                <div className="review-item-title">
                  <p className="extra-small bold opacity margin-0">
                    <FormattedMessage id="reservation_detail_review.communication" />
                  </p>
                  <Rating rating={communicationScore} />
                </div>
                <IonIcon src="assets/icons/icon-communication.svg" />
              </div>
            </div>
          </>
        )}
        {isBookingCom(rawReview) && (
          <>
            <div className="item">
              <div className="review-item">
                <div className="review-item-title">
                  <p className="extra-small bold opacity margin-0">
                    <FormattedMessage id="reservation_detail_review.facilities" />
                  </p>
                  <Rating rating={facilitiesScore} />
                </div>
                <IonIcon src="assets/icons/icon-appartment.svg" />
              </div>
            </div>
            <div className="item">
              <div className="review-item">
                <div className="review-item-title">
                  <p className="extra-small bold opacity margin-0">
                    <FormattedMessage id="reservation_detail_review.comfort" />
                  </p>
                  <Rating rating={comfortScore} />
                </div>
                <IonIcon src="assets/icons/icon-bed.svg" />
              </div>
            </div>
            <div className="item">
              <div className="review-item">
                <div className="review-item-title">
                  <p className="extra-small bold opacity margin-0">
                    <FormattedMessage id="reservation_detail_review.staff" />
                  </p>
                  <Rating rating={staffScore} />
                </div>
                <IonIcon src="assets/icons/icon-guests.svg" />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PublicReview;
